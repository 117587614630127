import React, { useState } from 'react';
import Rocket from './rocket.png'; // Import your image
import supabase from './supabaseClient'; // Import Supabase client

// const TypingEffect = ({ text }) => {
//   const [displayText, setDisplayText] = useState('');
//   const [index, setIndex] = useState(0);

//   useEffect(() => {
//     if (index < text.length) {
//       const timer = setTimeout(() => {
//         setDisplayText(prev => prev + text[index]);
//         setIndex(index + 1);
//       }, 100);
//       return () => clearTimeout(timer);
//     }
//   }, [index, text]);

//   return <span>{displayText}</span>;
// };

// const Feature = ({ icon: Icon, title, description }) => (
//   <div className="flex items-start space-x-3">
//     <Icon className="w-6 h-6 text-blue-500 mt-1" />
//     <div>
//       <h3 className="font-semibold">{title}</h3>
//       <p className="text-sm text-gray-600">{description}</p>
//     </div>
//   </div>
// );

function App() {
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [submissionStatus, setSubmissionStatus] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setIsValidEmail(true); // Reset validation on change
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(email));
    
    if (isValidEmail) {
      try {
        const { error } = await supabase
        .from('waitlist')
        .upsert(
          { email }, 
          { onConflict: 'email' }
        );
  

        if (error) {
          console.error('Error inserting data:', error);
          setSubmissionStatus('Failed to join the waitlist. Please try again.');
        } else {
          console.log('Form submitted with email:', email);
          setSubmissionStatus('Successfully joined the waitlist.');
          setEmail(''); // Clear email input after submission
        }
      } catch (error) {
        console.error('Error:', error);
        setSubmissionStatus("Oops something didn't work. Please try again.");
      }
    }
  };

  return (
<div className="flex flex-col min-h-screen bg-parakeetGrey">
  <main className="flex-grow">
    <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <div className="text-center mb-16 my-20">
        <h2 className="text-4xl sm:text-5xl font-extrabold text-parakeetGreen mb-4">
          Parakeet Labs
        </h2>
        <p className="text-xl text-vscodeGreen mb-8">AI-powered video comprehension</p>
        <img src={Rocket} alt="Descriptive Alt Text" className="mx-auto mb-8 w-1/4" />
        <p className="text-2xl text-vscodeGreen mb-8">Launching Soon</p>
        <form onSubmit={handleSubmit} className="space-y-4 sm:space-y-0 sm:flex sm:items-center sm:justify-center sm:space-x-3 py-10">
  <input
    type="email"
    placeholder="Your email address"
    value={email}
    onChange={handleEmailChange}
    className={`w-full sm:w-1/2 p-4 border-2 border-gray-300 rounded-md 
                focus:outline-none focus:ring-2 focus:ring-parakeetHover focus:border-parakeetGreen
                transition-all duration-300 ease-in-out
                bg-emailBox
                ${isValidEmail ? '' : 'border-red-500 focus:ring-red-500 focus:border-red-500'}
                placeholder-gray-500 text-gray-900
                shadow-sm hover:shadow-md
                sm:h-[58px]`}
    required
  />
  <button
    type="submit"
    className="w-auto sm:w-auto inline-flex items-center justify-center px-4 py-4 sm:py-4 border-2 border-parakeetGreen hover:border-parakeetHover text-gray-100 font-medium rounded-md text-gray-300 bg-parakeetGreen hover:bg-parakeetHover sm:h-[58px]"
  >
    Tell me more
  </button>
</form>



        {submissionStatus && (
          <p className={`mt-4 ${submissionStatus.includes('Failed') ? 'text-vscodeGreen' : 'text-vscodeGreen'}`}>
            {submissionStatus}
          </p>
        )}
      </div>
    </div>
  </main>

  <footer className="bg-parakeetGrey text-footerColor py-8">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex flex-col md:flex-row justify-center items-center">
        <p className="text-sm">&copy; 2024 Parakeet Labs. All rights reserved.</p>
      </div>
    </div>
  </footer>
</div>

  );
}

export default App;
